<template>
  <v-row align="start">
    <v-col cols=12 md=7>
      <div
        ref="map-controls"
        class="controls-buttons"
        v-show="mapControlsVisible"
      >
        <ButtonDraw
          v-show="!drawingMode"
          :text="this.$t('simulator.buttons.draw')"
          @click="draw"
        />
        <ButtonDraw
          v-show="drawingMode"
          :text="this.$t('simulator.buttons.cancel')"
          @click="cancel"
        />
        <ButtonDraw
          v-show="selectedId && !drawingMode"
          :text="this.$t('simulator.buttons.clear')"
          @click="clear"
        />
      </div>
      <GmapMap
        ref="map"
        class="map"
        :zoom="zoom"
        :center="center"
        :options="mapOptions"
      >
        <GmapMarker :position="this.marker ? this.location : null" />
      </GmapMap>
    </v-col>
    <v-col class="surface-distribution">
      <v-card flat class="mt-0 mt-md-12 ml-5 secondary--text" v-show="!surfaceControlsVisible">
        <div class="ml-4 mb-1">
          <v-avatar size="50" tile>
            <v-img src="@/assets/area-icon.svg"></v-img>
          </v-avatar>
        </div>
        <div class="mt-0 mt-md-6 ml-4">
          <p class="surface-title mb-0 pb-0">
            {{ this.$t("simulator.surface.information.draw") }}
          </p>
        </div>
      </v-card>
      <SliderTiltAzimuth
        class="mx-5 mt-12 secondary--text"
        v-show="mapControlsVisible && surfaceControlsVisible"
        ref="surface-controls"
        :title="this.$t('simulator.surface.information.characteristics')"
        :tilt="controls.tilt"
        :azimuth="controls.azimuth"
        @tiltChanged="tiltChanged"
        @azimuthChanged="azimuthChanged"
        @updateSurfaces="updateSurfaces"
      />
    </v-col>
  </v-row>
</template>

<script>
import uuidv4 from "uuid";
import ButtonDraw from "@/components/ui/ButtonDraw";
import SliderTiltAzimuth from "@/components/ui/SliderTiltAzimuth";

const SPAIN_ZOOM = 5;
const STREET_ZOOM = 19;
const SPAIN_CENTER = { lat: 40.1, lng: -3.75 };

const DEFAULT_AZIMUTH = 180;
const DEFAULT_TILT = 10;

export default {
  created() {
    this.PRIMARYCOLOR = this.$vuetify.theme.themes.light.primary;
  },
  props: {
    location: Object,
    reset: Boolean,
  },
  components: {
    ButtonDraw,
    SliderTiltAzimuth,
  },
  data() {
    return {
      map: null,
      marker: true,
      center: this.location ? this.location : SPAIN_CENTER,
      zoom: this.location ? STREET_ZOOM : SPAIN_ZOOM,
      isurfaces: {},
      selectedId: null,
      drawingMode: false,
      drawingManager: null,
      mapControlsVisible: false,
      surfaceControlsVisible: false,
      mapOptions: {
        mapTypeId: "satellite",
        tilt: 0,
        zoomControl: true,
        scaleControl: true,
        disableDefaultUI: true,
        // mapTypeControl: true
      },
      controls: {
        tilt: DEFAULT_TILT,
        azimuth: DEFAULT_AZIMUTH,
      },
    };
  },
  computed: {
    selectedSurface() {
      if (this.selectedId) {
        return this.isurfaces[this.selectedId];
      }
      return null;
    },
  },
  watch: {
    reset: {
      handler: "setReset",
      inmediate: true,
    },
    location: {
      handler: "setLocation",
      inmediate: true,
    },
  },
  methods: {
    draw() {
      this.marker = false;
      this.unselectSurface(this.selectedSurface);
      this.drawingMode = true;
      this.drawingManager.setDrawingMode("polygon");
      this.drawingManager.polygonOptions.fillColor = this.PRIMARYCOLOR;
    },
    cancel() {
      this.drawingMode = false;
      this.drawingManager.setDrawingMode(null);
      this.selectSurface(this.selectedSurface);
    },
    clear() {
      const surface = this.selectedSurface;
      if (surface) {
        this.removeSurface(this.selectedId, surface);
        this.selectedId = null;
        this.updateSurfaces();
      }
    },
    clearAll() {
      for (let id in this.isurfaces) {
        const surface = this.isurfaces[id];
        if (surface) {
          this.removeSurface(id, surface);
        }
      }
      this.selectedId = null;
      this.updateSurfaces();
      this.marker = true;
    },
    selectSurface(surface) {
      if (surface) {
        surface.shape.setEditable(true);
        if (surface.orientation) {
          this.controls.azimuth = surface.orientation.azimuth;
          this.controls.tilt = surface.orientation.tilt;
        }
        this.surfaceControlsVisible = true;
      }
    },
    unselectSurface(surface) {
      if (surface) {
        surface.shape.setEditable(false);
      }
      this.surfaceControlsVisible = false;
      this.controls.azimuth = DEFAULT_AZIMUTH;
      this.controls.tilt = DEFAULT_TILT;
    },
    deleteSurface(id) {
      this.$delete(this.isurfaces, id);
    },
    subscribeSurfaceListeners(id, surface) {
      if (surface && surface.shape) {
        let isBeingDragged = false;
        const shape = surface.shape;
        const path = shape.getPath();
        const event = window.google.maps.event;
        surface.listeners.push(
          event.addListener(path, "insert_at", () => {
            this.setGeometry(id);
          })
        );
        surface.listeners.push(
          event.addListener(path, "remove_at", () => {
            this.setGeometry(id);
          })
        );
        surface.listeners.push(
          event.addListener(path, "set_at", () => {
            if (!isBeingDragged) {
              this.setGeometry(id);
            }
          })
        );
        surface.listeners.push(
          event.addListener(shape, "dragstart", () => {
            isBeingDragged = true;
            this.unregisterArrow(surface);
          })
        );
        surface.listeners.push(
          event.addListener(shape, "dragend", () => {
            isBeingDragged = false;
            this.setGeometry(id);
            this.registerArrow(surface);
          })
        );
        surface.listeners.push(
          event.addListener(shape, "mousedown", () => {
            const prevShape =
              this.selectedSurface && this.selectedSurface.shape;
            if (!this.drawingMode && shape !== prevShape) {
              if (prevShape) {
                this.unselectSurface(this.selectedSurface);
              }
              this.selectedId = id;
              this.selectSurface(this.selectedSurface);
            }
          })
        );
      }
    },
    unsubscribeSurfaceListeners(surface) {
      if (surface) {
        surface.listeners.forEach((listener) => {
          window.google.maps.event.removeListener(listener);
        });
        surface.listeners = [];
      }
    },
    subscribeDrawingMapListeners() {
      window.google.maps.event.addListenerOnce(this.map, "tilesloaded", () => {
        this.mapControlsVisible = true;
      });
      window.google.maps.event.addListener(this.map, "mousedown", () => {
        if (!this.drawingMode) {
          const prevSurface = this.selectedSurface;
          if (prevSurface) {
            prevSurface.shape.setEditable(false);
            this.selectedId = null;
            this.surfaceControlsVisible = false;
          }
        }
      });
      window.google.maps.event.addListener(
        this.drawingManager,
        "overlaycomplete",
        (event) => {
          const id = uuidv4();
          const shape = event.overlay;
          if (this.drawingMode) {
            let orientation;
            orientation = {
              azimuth: DEFAULT_AZIMUTH,
              tilt: DEFAULT_TILT,
            };
            this.createSurface(id, shape, orientation);
            this.setGeometry(id);
            this.drawingMode = false;
            this.drawingManager.setDrawingMode(null);
            this.surfaceControlsVisible = true;
          } else {
            this.unregisterShape(shape);
          }
        }
      );
    },
    registerShape(shape) {
      if (shape) {
        shape.setMap(this.map);
      }
    },
    unregisterShape(shape) {
      if (shape) {
        shape.setMap(null);
      }
    },
    setGeometry(id) {
      if (id) {
        const surface = this.isurfaces[id];
        const path = surface.shape.getPath();
        const geometry = path.getArray().map((coordinate) => ({
          latitude: coordinate.lat(),
          longitude: coordinate.lng(),
        }));
        const centroid = {
          lat: mean(geometry, "latitude"),
          lng: mean(geometry, "longitude"),
        };
        const area = window.google.maps.geometry.spherical.computeArea(path);

        surface.geometry = geometry;
        surface.centroid = centroid;
        surface.area = area;

        this.updateArrow(surface);
        this.updateSurfaces();
      }
    },
    resetGeometry(id) {
      if (id) {
        const surface = this.isurfaces[id];

        surface.geometry = [];
        surface.centroid = null;
        surface.area = null;

        this.unregisterArrow(surface);
        this.updateSurfaces();
      }
    },

    createSurface(id, shape, orientation) {
      let arrowShape = this.createArrow();
      const surface = {
        shape,
        arrowShape,
        geometry: [],
        orientation,
        listeners: [],
      };

      this.selectedId = id;
      this.isurfaces[id] = surface;
      this.subscribeSurfaceListeners(id, surface);
    },
    removeSurface(id, surface) {
      this.unselectSurface(surface);
      this.unsubscribeSurfaceListeners(surface);
      this.unregisterShape(surface.shape);
      this.resetGeometry(id);
      this.deleteSurface(id);
    },
    createArrow() {
      const arrowIcon = window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW;
      const arrowShape = new window.google.maps.Polyline({
        path: [],
        strokeColor: "white",
        strokeWeight: 3,
        zIndex: 9999,
        icons: [
          {
            icon: { path: arrowIcon },
            offset: "100%",
          },
        ],
      });
      this.registerShape(arrowShape);
      return arrowShape;
    },
    updateArrow(surface) {
      if (
        surface.arrowShape &&
        surface.area &&
        surface.centroid &&
        surface.orientation
      ) {
        const angle = ((90 - surface.orientation.azimuth) * Math.PI) / 180;
        const length = 0.00004 + 0.000008 * Math.sqrt(surface.area);
        const x = length * Math.cos(angle);
        const y = length * Math.sin(angle);
        const lat = surface.centroid.lat;
        const lng = surface.centroid.lng;
        const scaleFactor = Math.cos((surface.centroid.lat * Math.PI) / 180);

        surface.arrowShape.setPath([
          { lng, lat },
          { lng: lng + x, lat: lat + y * scaleFactor },
        ]);
      }
    },
    registerArrow(surface) {
      if (surface && surface.arrowShape) {
        this.registerShape(surface.arrowShape);
      }
    },
    unregisterArrow(surface) {
      if (surface && surface.arrowShape) {
        this.unregisterShape(surface.arrowShape);
      }
    },
    azimuthChanged(value) {
      const surface = this.selectedSurface;
      if (surface) {
        surface.orientation.azimuth = value;
        this.updateArrow(surface);
      }
      this.controls.azimuth = value;
    },
    tiltChanged(value) {
      const surface = this.selectedSurface;
      if (surface) {
        surface.orientation.tilt = value;
      }
      this.controls.tilt = value;
      if (!value && surface.arrowShape) {
        this.unregisterArrow(surface);
      }
      if (value && surface.arrowShape) {
        this.registerArrow(surface);
      }
    },
    updateSurfaces() {
      const surfaces = Object.values(this.isurfaces).map((s) => ({
        geometry: s.geometry,
        orientation: s.orientation,
      }));
      const centroids = Object.values(this.isurfaces)
        .filter((s) => s.centroid)
        .map((s) => s.centroid);

      let centroid = null;
      if (centroids.length) {
        centroid = {
          lat: mean(centroids, "lat"),
          lng: mean(centroids, "lng"),
        };
      }
      this.$emit("surfacesChanged", { surfaces, centroid });
    },
    setLocation() {
      this.map.setCenter(this.location);
      if (STREET_ZOOM - this.map.zoom > 3) {
        this.map.setZoom(STREET_ZOOM);
      }
    },
    setReset() {
      this.clearAll();
      this.drawingMode = false;
    },
  },
  async mounted() {
    // Wait for the map to be ready
    await this.$refs.map.$mapPromise;

    const fillColor = this.PRIMARYCOLOR;
    const fillOpacity = 0.9;
    const strokeWeight = 0;
    const editable = true;
    const draggable = true;

    this.map = this.$refs.map.$mapObject;
    this.map.controls[window.google.maps.ControlPosition.LEFT_TOP].push(
      this.$refs["map-controls"]
    );
    this.map.controls[window.google.maps.ControlPosition.LEFT_BOTTOM].push(
      this.$refs["surface-controls"]
    );
    this.drawingManager = new window.google.maps.drawing.DrawingManager({
      map: this.map,
      drawingControl: false,
      polygonOptions: {
        fillColor,
        fillOpacity,
        strokeWeight,
        editable,
        draggable,
        zIndex: 2,
      },
    });
    this.subscribeDrawingMapListeners();
  },
};

function mean(array, key) {
  const len = array.length;
  if (len) {
    return array.reduce((sum, item) => sum + item[key], 0) / len;
  }
}
</script>

<style lang="scss" scoped>
.map {
  width: 100%;
  height: 405px;
}
.controls-buttons {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 25px;
  margin-left: 35px;
}
</style>

