<template>
  <div>
    <p class="mb-8">{{ title }}</p>
    <span><v-icon color="secondary" left>mdi-angle-acute</v-icon>{{ tiltTitle }}</span>
    <v-slider
      class="mt-2 mb-4 custom-slider secondary--text"
      hide-details
      min="0"
      max="60"
      step="10"
      :value="tilt"
      @input="tiltChanged"
      @change="updateSurfaces"
    ></v-slider>
    <span><v-icon color="secondary" left>mdi-compass-outline</v-icon>{{ azimutTitle }}</span>
    <v-slider
      class="mt-2 custom-slider secondary--text"
      hide-details
      min="0"
      max="360"
      step="1"
      :disabled="!tilt"
      :value="azimuth"
      @input="azimuthChanged"
      @change="updateSurfaces"
    ></v-slider>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
    tilt: Number,
    azimuth: Number,
  },
  created() {
    this.PRIMARYCOLOR = this.$vuetify.theme.themes.light.primary;
  },
  computed: {
    tiltTitle() {
      const label = this.$t("components.slider_tilt_azimuth.tilt");
      return `${label}: ${this.tilt}°`;
    },
    azimutTitle() {
      const label = this.$t("components.slider_tilt_azimuth.azimuth");
      let name = "Sur"
      if (this.azimuth < 22.5) {
        name = "Norte"
      } else if (this.azimuth < 67.5) {
        name = "Nordeste"
      } else if (this.azimuth < 112.5) {
        name = "Este"
      } else if (this.azimuth < 157.5) {
        name = "Sudeste"
      } else if (this.azimuth < 202.5) {
        name = "Sur"
      } else if (this.azimuth < 247.5) {
        name = "Sudoeste"
      } else if (this.azimuth < 292.5) {
        name = "Oeste"
      } else if (this.azimuth < 337.5) {
        name = "Noroeste"
      } else {
        name = "Norte"
      }
      if (!this.tilt) {
        name = "(no aplica)"
      }
      return `${label}: ${name}`;
    },
  },
  methods: {
    azimuthChanged(value) {  
      this.$emit('azimuthChanged', value)
    },
    tiltChanged(value) {
      this.$emit('tiltChanged', value)
    },
    updateSurfaces() {
      this.$emit('updateSurfaces')
    },
  },
};
</script>

<style lang="scss" scoped>
.custom-slider::v-deep .v-slider__track-fill {
  background-color: var(--v-primary-base) !important;
}
.custom-slider::v-deep .v-slider__track-background.primary {
  background-color: var(--v-primary-base) !important;
}
.custom-slider::v-deep .v-slider__thumb:after {
  color: inherit;
  width: 400%;
  height: 400%;
  opacity: 0.5;
  border-radius: 50%;
  background: transparent;
  position: absolute;
  left: -150%;
  top: -150%;
}
.custom-slider::v-deep .v-slider__thumb:before {
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  color: white;
  width: 250%;
  height: 250%;
  opacity: 0.5;
  border-radius: 50%;
  border-style: solid;
  border-width: 2px;
  border-color: var(--v-primary-base);
  background: currentColor;
  opacity: 1;
  position: absolute;
  left: -75%;
  top: -75%;
  transform: scale(1);
  pointer-events: none;
}
</style>
