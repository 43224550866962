<template>
  <v-btn
    class="mb-3 button_map"
    min-width="110"
    rounded
    outlined
    :color="PRIMARYCOLOR"
    @click="$emit('click')"
  >
    {{ text }}
  </v-btn>
</template>

<script>
export default {
  props: {
    text: String,
  },
  created() {
    this.PRIMARYCOLOR = this.$vuetify.theme.themes.light.primary;
  },
};
</script>

<style lang="scss" scoped>
.button_map {
  background-color: white;
}
</style>
